import { BrowserRouter, Routes, Route } from 'react-router-dom';
import './App.css';
import Client from './pages/client/client';
import Driver from './pages/driver/driver';
import Register from './pages/register/register';
import { useTelegram } from './hooks/useTelegram';
import { useEffect } from 'react';

function App() {
  const { tg } = useTelegram();

  useEffect(() => {
    tg.ready();
  });

  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Client />} />
          <Route path="/driver" element={<Driver />} />
          <Route path="/register" element={<Register />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
