import React, { useCallback, useEffect, useState } from 'react';
import './client.css';
import DatePicker from 'react-datepicker';
import PhoneInput from 'react-phone-number-input';
import 'react-datepicker/dist/react-datepicker.css';
import { useTelegram } from '../../hooks/useTelegram';

const Client = () => {
  const [orderData, setOrderData] = useState({
    route: '',
    region_id: '',
    district_id: '',
    date: new Date(),
    time: '',
    total_passengers: '1',
  });
  const [phoneNumber, setPhoneNumber] = useState('');
  const [regions, setRegions] = useState([]);
  const [districts, setDistricts] = useState([]);

  const { tg } = useTelegram();

  const onSendData = useCallback(() => {
    tg.sendData(
      JSON.stringify({
        ...orderData,
        time: +orderData.time,
        phone_number: +phoneNumber,
        total_passengers: +orderData.total_passengers,
      })
    );
  }, [orderData, phoneNumber, tg]);

  useEffect(() => {
    tg.onEvent('mainButtonClicked', onSendData);
    return () => {
      tg.offEvent('mainButtonClicked', onSendData);
    };
  }, [onSendData, tg]);

  useEffect(() => {
    tg.MainButton.setParams({
      text: 'Order a Taxi',
      color: '#FFBE30',
    });
  }, [tg]);

  useEffect(() => {
    if (
      orderData.route &&
      orderData.region_id &&
      orderData.district_id &&
      orderData.time &&
      phoneNumber.length === 13
    ) {
      tg.MainButton.show();
    } else {
      tg.MainButton.hide();
    }
  }, [orderData, phoneNumber, tg]);

  const handleDateChange = (date) => {
    setOrderData({ ...orderData, date });
  };

  useEffect(() => {
    const fetchRegions = async () => {
      try {
        const response = await fetch('http://185.217.131.54:7000/regions');
        if (response.ok) {
          const data = await response.json();
          if (data.data?.regions) {
            setRegions(data.data.regions);
          }
        } else {
          throw new Error('Failed to fetch regions');
        }
      } catch (error) {
        console.error('Error fetching regions:', error);
      }
    };

    fetchRegions();
  }, []);

  const handleChange = async (event) => {
    const name = event.target.name;
    const value = event.target.value;

    setOrderData({
      ...orderData,
      [name]: value,
    });

    if (name === 'region_id') {
      try {
        const response = await fetch(
          `http://185.217.131.54:7000/regions/${value}/districts`
        );
        if (response.ok) {
          const data = await response.json();
          if (data.data?.districts) {
            setDistricts(data.data.districts);
          }
        } else {
          throw new Error('Failed to fetch districts');
        }
      } catch (error) {
        console.error('Error fetching districts:', error);
      }
    }
  };

  const today = new Date();
  const sixDaysAhead = new Date(new Date().getTime() + 6 * 86400000);

  return (
    <div className="order_taxi_wrap">
      <h1 className="title">
        City <b>Taxi</b>
      </h1>
      <div className="taxi_form">
        <div style={{ display: 'flex', justifyContent: 'center', gap: '5%' }}>
          <button
            className={
              orderData.route === 'fromTashkent' ? 'active' : 'tashkentBtn'
            }
            name="route"
            value="fromTashkent"
            onClick={handleChange}
          >
            From Tashkent
          </button>
          <button
            className={
              orderData.route === 'toTashkent' ? 'active' : 'tashkentBtn'
            }
            name="route"
            value="toTashkent"
            onClick={handleChange}
          >
            To Tashkent
          </button>
        </div>

        <div style={{ marginTop: '5%' }}>
          <label style={{ display: 'flex', marginBottom: '2%' }}>Region:</label>
          <select
            name="region_id"
            value={orderData.region_id}
            onChange={handleChange}
          >
            <option value="" disabled hidden>
              Select region
            </option>
            {regions.map((region) => (
              <option key={region._id} value={region._id}>
                {region?.translations?.uz?.name}
              </option>
            ))}
          </select>
        </div>

        {/* Dropdown for districts */}
        <div style={{ marginTop: '0%' }}>
          <label style={{ display: 'flex', marginBottom: '2%' }}>
            District:
          </label>
          <select
            name="district_id"
            value={orderData.district_id}
            onChange={handleChange}
          >
            <option value="" disabled hidden>
              Select district
            </option>
            {districts.map((district) => (
              <option key={district._id} value={district._id}>
                {district?.translations?.uz?.name}
              </option>
            ))}
          </select>
        </div>

        <div style={{ marginTop: '0%' }}>
          <label style={{ display: 'flex', marginBottom: '2%' }}>Date:</label>
          <DatePicker
            selected={orderData.date}
            onChange={handleDateChange}
            dateFormat="dd/MM/yyyy"
            minDate={today}
            maxDate={sixDaysAhead}
          />
        </div>

        <div style={{ marginTop: '0%' }}>
          <label style={{ display: 'flex', marginBottom: '2%' }}>Time:</label>
          <select name="time" value={orderData.time} onChange={handleChange}>
            <option value="" disabled hidden>
              Select time
            </option>
            <option value="0">00:00 - 01:00</option>
            <option value="60">01:00 - 02:00</option>
            <option value="120">02:00 - 03:00</option>
            <option value="180">03:00 - 04:00</option>
            <option value="240">04:00 - 05:00</option>
            <option value="300">05:00 - 06:00</option>
            <option value="360">06:00 - 07:00</option>
            <option value="420">07:00 - 08:00</option>
            <option value="480">08:00 - 09:00</option>
            <option value="540">09:00 - 10:00</option>
            <option value="600">10:00 - 11:00</option>
            <option value="660">11:00 - 12:00</option>
            <option value="720">12:00 - 13:00</option>
            <option value="780">13:00 - 14:00</option>
            <option value="840">14:00 - 15:00</option>
            <option value="900">15:00 - 16:00</option>
            <option value="960">16:00 - 17:00</option>
            <option value="1020">17:00 - 18:00</option>
            <option value="1080">18:00 - 19:00</option>
            <option value="1140">19:00 - 20:00</option>
            <option value="1200">20:00 - 21:00</option>
            <option value="1260">21:00 - 22:00</option>
            <option value="1320">22:00 - 23:00</option>
            <option value="1380">23:00 - 00:00</option>
          </select>
        </div>

        <div style={{ marginTop: '0%' }}>
          <label style={{ display: 'flex', marginBottom: '2%' }}>
            Phone Number:
          </label>
          <PhoneInput
            countries={['UZ']}
            country="UZ"
            international={true}
            countryCallingCodeEditable={false}
            defaultCountry="UZ"
            placeholder="Enter phone number"
            value={phoneNumber}
            onChange={setPhoneNumber}
            maxLength={17}
          />
        </div>

        <div>
          <p> Total Passengers:</p>
          <div className="passengerBtns">
            <button
              className={orderData.total_passengers === '1' ? 'active' : ''}
              name="total_passengers"
              value="1"
              onClick={handleChange}
            >
              1
            </button>
            <button
              className={orderData.total_passengers === '2' ? 'active' : ''}
              name="total_passengers"
              value="2"
              onClick={handleChange}
            >
              2
            </button>
            <button
              className={orderData.total_passengers === '3' ? 'active' : ''}
              name="total_passengers"
              value="3"
              onClick={handleChange}
            >
              3
            </button>
            <button
              className={orderData.total_passengers === '4' ? 'active' : ''}
              name="total_passengers"
              value="4"
              onClick={handleChange}
            >
              4
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Client;
