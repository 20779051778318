import React, { useCallback, useEffect, useState } from 'react';
import './driver.css';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useTelegram } from '../../hooks/useTelegram';

const Driver = () => {
  const [serviceData, setServiceData] = useState({
    route: '',
    region_id: '',
    date: new Date(),
    time: '',
    total_passengers: '4',
  });
  const [regions, setRegions] = useState([]);

  const { tg } = useTelegram();

  const onSendData = useCallback(() => {
    tg.sendData(
      JSON.stringify({
        ...serviceData,
        time: +serviceData.time,
        total_passengers: +serviceData.total_passengers,
      })
    );
  }, [serviceData, tg]);

  useEffect(() => {
    tg.onEvent('mainButtonClicked', onSendData);
    return () => {
      tg.offEvent('mainButtonClicked', onSendData);
    };
  }, [onSendData, tg]);

  useEffect(() => {
    tg.MainButton.setParams({
      text: 'Search clients',
      color: '#FFBE30',
    });
  }, [tg]);

  useEffect(() => {
    if (serviceData.route && serviceData.region_id && serviceData.time) {
      tg.MainButton.show();
    } else {
      tg.MainButton.hide();
    }
  }, [serviceData, tg]);

  const handleDateChange = (date) => {
    setServiceData({ ...serviceData, date });
  };

  useEffect(() => {
    const fetchRegions = async () => {
      try {
        const response = await fetch('http://185.217.131.54:7000/regions');
        if (response.ok) {
          const data = await response.json();
          if (data.data?.regions) {
            setRegions(data.data.regions);
          }
        } else {
          throw new Error('Failed to fetch regions');
        }
      } catch (error) {
        console.error('Error fetching regions:', error);
      }
    };

    fetchRegions();
  }, []);

  const handleChange = async (event) => {
    const name = event.target.name;
    const value = event.target.value;

    setServiceData({
      ...serviceData,
      [name]: value,
    });
  };

  const today = new Date();
  const sixDaysAhead = new Date(new Date().getTime() + 6 * 86400000);

  return (
    <div className="order_taxi_wrap">
      <h1 className="title">
        City <b>Taxi</b>
      </h1>
      <div className="taxi_form">
        <div style={{ display: 'flex', justifyContent: 'center', gap: '5%' }}>
          <button
            className={
              serviceData.route === 'fromTashkent' ? 'active' : 'tashkentBtn'
            }
            name="route"
            value="fromTashkent"
            onClick={handleChange}
          >
            From Tashkent
          </button>
          <button
            className={
              serviceData.route === 'toTashkent' ? 'active' : 'tashkentBtn'
            }
            name="route"
            value="toTashkent"
            onClick={handleChange}
          >
            To Tashkent
          </button>
        </div>

        <div style={{ marginTop: '5%' }}>
          <label style={{ display: 'flex', marginBottom: '2%' }}>Region:</label>
          <select
            name="region_id"
            value={serviceData.region_id}
            onChange={handleChange}
          >
            <option value="" disabled hidden>
              Select region
            </option>
            {regions.map((region) => (
              <option key={region._id} value={region._id}>
                {region?.translations?.uz?.name}
              </option>
            ))}
          </select>
        </div>

        <div>
          <label style={{ display: 'flex', marginBottom: '2%' }}>Date:</label>
          <DatePicker
            selected={serviceData.date}
            onChange={handleDateChange}
            dateFormat="dd/MM/yyyy"
            minDate={today}
            maxDate={sixDaysAhead}
          />
        </div>

        <div>
          <label style={{ display: 'flex', marginBottom: '2%' }}>Time:</label>
          <select name="time" value={serviceData.time} onChange={handleChange}>
            <option value="" disabled hidden>
              Select time
            </option>
            <option value="0">00:00 - 01:00</option>
            <option value="60">01:00 - 02:00</option>
            <option value="120">02:00 - 03:00</option>
            <option value="180">03:00 - 04:00</option>
            <option value="240">04:00 - 05:00</option>
            <option value="300">05:00 - 06:00</option>
            <option value="360">06:00 - 07:00</option>
            <option value="420">07:00 - 08:00</option>
            <option value="480">08:00 - 09:00</option>
            <option value="540">09:00 - 10:00</option>
            <option value="600">10:00 - 11:00</option>
            <option value="660">11:00 - 12:00</option>
            <option value="720">12:00 - 13:00</option>
            <option value="780">13:00 - 14:00</option>
            <option value="840">14:00 - 15:00</option>
            <option value="900">15:00 - 16:00</option>
            <option value="960">16:00 - 17:00</option>
            <option value="1020">17:00 - 18:00</option>
            <option value="1080">18:00 - 19:00</option>
            <option value="1140">19:00 - 20:00</option>
            <option value="1200">20:00 - 21:00</option>
            <option value="1260">21:00 - 22:00</option>
            <option value="1320">22:00 - 23:00</option>
            <option value="1380">23:00 - 00:00</option>
          </select>
        </div>

        <div>
          <p> Total Passengers:</p>
          <div className="passengerBtns">
            <button
              className={serviceData.total_passengers === '1' ? 'active' : ''}
              name="total_passengers"
              value="1"
              onClick={handleChange}
            >
              1
            </button>
            <button
              className={serviceData.total_passengers === '2' ? 'active' : ''}
              name="total_passengers"
              value="2"
              onClick={handleChange}
            >
              2
            </button>
            <button
              className={serviceData.total_passengers === '3' ? 'active' : ''}
              name="total_passengers"
              value="3"
              onClick={handleChange}
            >
              3
            </button>
            <button
              className={serviceData.total_passengers === '4' ? 'active' : ''}
              name="total_passengers"
              value="4"
              onClick={handleChange}
            >
              4
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Driver;
