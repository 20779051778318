import React, { useCallback, useEffect, useState } from 'react';
import './register.css';
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';
import { useTelegram } from '../../hooks/useTelegram';

const Register = () => {
  const [driverData, setDriverData] = useState({
    first_name: '',
    last_name: '',
    car_model: '',
    car_number: {
      region_num: '',
      main_num: '',
    },
    region_id: '',
  });

  const [phoneNumber, setPhoneNumber] = useState('');
  const [regions, setRegions] = useState([]);

  const { tg } = useTelegram();

  const onSendData = useCallback(() => {
    tg.sendData(
      JSON.stringify({
        ...driverData,
        car_number:
          driverData.car_number.region_num + driverData.car_number.main_num,
        phone_number: +phoneNumber,
      })
    );
  }, [driverData, phoneNumber, tg]);

  useEffect(() => {
    tg.onEvent('mainButtonClicked', onSendData);
    return () => {
      tg.offEvent('mainButtonClicked', onSendData);
    };
  }, [onSendData, tg]);

  useEffect(() => {
    tg.MainButton.setParams({
      text: 'Register',
      color: '#FFBE30',
    });
  }, [tg]);

  useEffect(() => {
    if (
      driverData.first_name &&
      driverData.last_name &&
      driverData.car_model &&
      driverData.region_id &&
      driverData.car_number.region_num &&
      driverData.car_number.main_num &&
      phoneNumber
    ) {
      tg.MainButton.show();
    } else {
      tg.MainButton.hide();
    }
  }, [driverData, phoneNumber, tg]);

  useEffect(() => {
    const fetchRegions = async () => {
      try {
        const response = await fetch('http://185.217.131.54:7000/regions');
        if (response.ok) {
          const data = await response.json();
          if (data.data?.regions) {
            setRegions(data.data.regions);
          }
        } else {
          throw new Error('Failed to fetch regions');
        }
      } catch (error) {
        console.error('Error fetching regions:', error);
      }
    };

    fetchRegions();
  }, []);

  const handleChange = async (event) => {
    const name = event.target.name;
    const value = event.target.value;

    setDriverData({
      ...driverData,
      [name]: value,
    });

    if (name === 'region_num' || name === 'main_num') {
      setDriverData({
        ...driverData,
        car_number: {
          ...driverData.car_number,
          [name]: name === 'main_num' ? value.toUpperCase() : value,
        },
      });
    }
  };

  const carModels = ['Model A', 'Model B', 'Model C'];

  return (
    <div className="driver_wrap">
      <h1 className="title">
        Register <b>Driver</b>
      </h1>
      <div className="taxi_form">
        <div style={{ marginTop: '0%' }}>
          <label style={{ display: 'flex', marginBottom: '2%' }}>Name:</label>
          <input
            placeholder="Your name"
            type="text"
            name="first_name"
            value={driverData.first_name}
            onChange={handleChange}
          />
        </div>
        <div>
          <label style={{ display: 'flex', marginBottom: '2%' }}>
            Surname:
          </label>
          <input
            placeholder="Your surname"
            type="text"
            name="last_name"
            value={driverData.last_name}
            onChange={handleChange}
          />
        </div>
        <div>
          <label style={{ display: 'flex', marginBottom: '2%' }}>
            Car Model:
          </label>
          <select
            name="car_model"
            value={driverData.car_model}
            onChange={handleChange}
          >
            <option value="" disabled hidden>
              Select Car Model
            </option>
            {carModels.map((model, index) => (
              <option key={index} value={model}>
                {model}
              </option>
            ))}
          </select>
        </div>
        <label style={{ display: 'flex', marginBottom: '-3%' }}>
          Car Number:
        </label>
        <div
          className="input-group mb-3"
          style={{
            marginTop: '5%',
            border: '1px solid #8E8E8E',
            borderRadius: '4px',
          }}
        >
          <select
            style={{ border: 'none' }}
            className="custom-select"
            name="region_num"
            value={driverData.car_number.region_num}
            onChange={handleChange}
          >
            <option value="" disabled hidden>
              City code
            </option>
            <option value="01">01</option>
            <option value="10">10</option>
            <option value="40">40</option>
            <option value="50">50</option>
          </select>
          <input
            style={{ width: '65%', border: 'none' }}
            type="text"
            className="form-control"
            aria-label="Text input with dropdown button"
            name="main_num"
            value={driverData.car_number.main_num}
            onChange={handleChange}
            placeholder="Z 777 ZZ"
            maxLength={6}
          />
        </div>
        <div>
          <label style={{ display: 'flex', marginBottom: '2%' }}>
            Select Region:
          </label>
          <select
            name="region_id"
            value={driverData.region_id}
            onChange={handleChange}
          >
            <option value="" disabled hidden>
              Select region
            </option>
            {regions.map((region, index) => (
              <option key={index} value={region._id}>
                {region.translations.uz.name}
              </option>
            ))}
          </select>
        </div>

        <div>
          <label style={{ display: 'flex', marginBottom: '2%' }}>
            Phone Number:
          </label>
          <PhoneInput
            countries={['UZ']}
            country="UZ"
            international={true}
            countryCallingCodeEditable={false}
            defaultCountry="UZ"
            placeholder="Enter phone number"
            value={phoneNumber}
            onChange={setPhoneNumber}
            maxLength={17}
          />
        </div>
      </div>
    </div>
  );
};

export default Register;
